/* eslint-disable no-nested-ternary */
import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import Tags from '../components/Tags'
import MarkdownContent from '../components/MarkdownContent'
import NewsSnippet from '../components/NewsSnippet'

import '../components/index.css'

export default ({ data }) => {
  const { frontmatter } = data.GetIndexPage
  return (
    <div>
      <SEO keywords="szkoła językowa, Bielsko-Biała, grecki, język grecki, kursy języka greckiego" />
      <Layout index="true">
        <div className="index">
          <div className="index--section">
            <Img className="index__image--main left" fluid={frontmatter.image_2.childImageSharp.fluid} alt="Index Page" />
            <div className="index__description">
              <MarkdownContent content={frontmatter.description_part_2} className="index__description-text" />
            </div>
          </div>
          <div className="index--section">
            <Img className="index__image--main right" fluid={frontmatter.image_3.childImageSharp.fluid} alt="Index Page" />
            <div className="index__description">
              <MarkdownContent content={frontmatter.description_part_3} className="index__description-text" />
            </div>
          </div>
          <div className="index__offer--wrapper">
            <div className="index__offer--inner">
              <h2 className="index__offer--header">
                Oferta
              </h2>
              <div className="index__offer--grid">
                {data.GetOffer.edges.map(edge => (
                  <Link key={`${edge.node.id} link`} className="index__offer--link" style={{ borderRadius: '0', backgroundImage: `url(${edge.node.frontmatter.picture.childImageSharp.fluid.src})`, backgroundSize: 'cover' }} to={edge.node.fields.slug}>
                    <div key={`${edge.node.id} item`} className="index__offer-item">
                      <div key={`${edge.node.id} label`} className="index__offer-item--label">{edge.node.frontmatter.name}</div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="index__news">
            <div className="index__news-header">
              <h1 className="index__news-header-text">Aktualności</h1>
            </div>
            <div className="index__news-feed">
              {data.GetLatestNews.edges.map(edge => (
                <NewsSnippet
                  key={edge.node.id}
                  linkDestination={edge.node.fields.slug}
                  image={edge.node.frontmatter.image}
                  imgAlt={edge.node.id}
                  title={edge.node.frontmatter.title}
                  shortDescription={edge.node.frontmatter.short_description}
                />
              ))}
            </div>
          </div>
          <div className="index__tags">
            <Tags taggedPages={data.TaggedPages.edges} targetPage="" />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query {
    GetIndexPage: markdownRemark( frontmatter: { templateKey: { eq: "index-page" }}) {
      frontmatter {
        title
        description_part_2
        description_part_3
        image_2 {
          childImageSharp {
            fluid(maxWidth: 650, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image_3 {
          childImageSharp {
            fluid(maxWidth: 650, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    GetLatestNews: allMarkdownRemark( sort: { order: DESC, fields: [frontmatter___date]}, limit: 4, filter: { frontmatter: { templateKey: { eq: "news-post" }}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            short_description
            date
          }
        }
      }
    }
    GetOffer: allMarkdownRemark( sort: { order: ASC, fields: [frontmatter___position]}, filter: { frontmatter: { templateKey: { eq: "offer-page" }}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            position
            picture {
              childImageSharp {
                fluid(maxWidth: 450, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            name
          }
        }
      }
    }
    TaggedPages: allMarkdownRemark(filter: {frontmatter: {templateKey: {regex: "/news-post|offer-page/"}}}) {
      edges {
        node {
          id
          frontmatter {
            tags
          }
        }
      }
    }
  }
`
